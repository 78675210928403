import React, { useEffect, useState } from 'react';
import { Appointment } from "./AppointmentList";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

interface PaymentProps {
  appointment: Appointment;
  callBack: () => void;
}

const sizeList = [
  { id: 'P', name: 'Pequeno' },
  { id: 'M', name: 'Médio' },
  { id: 'G', name: 'Grande' },
]

const paymentMethosArray = ["Dinheiro", "Pix", "Cartão (Débito)", "Cartão (Crédito)"];

const BathAppointmentPayment: React.FC<PaymentProps> = (props) => {
  const [total, setTotal] = useState('');
  const [totalToBePaid, setTotalToBePaid] = useState<number>();
  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [payment, setPayment] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = async () => {
    if (validateInputs()) return;

    const paymentData = {
      paymentMethod,
      payment,
      discount,
    };

    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment/${props.appointment.id}/payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(paymentData),
      });
      if (response.ok) {
        navigate("/banho/agendamentos");
      } else {
        setErrorMessage("Erro ao cadastrar o agendamento");
      }
    } catch (error) {
      setErrorMessage("Erro ao cadastrar o agendamento");
    }

    props.callBack();
  };

  const validateInputs = (): boolean => {
    if (!paymentMethod) {
      alert("Selecione a forma de pagamento");
      return true;
    }
    if (!payment) {
      alert("Informe o valor do pagamento");
      return true;
    }
    if (!totalToBePaid) {
      alert("Erro ao calcular o total a ser pago");
      return true;
    }
    if (payment > totalToBePaid) {
      alert("O pagamento não pode ser maior que o total a ser pago");
      return true;
    }
    if (discount > totalToBePaid) {
      alert("O desconto não pode ser maior que o total a ser pago");
      return true;
    }

    return false;
  }

  useEffect(() => {
    let totalAlreadyPaid = props.appointment.totalAlreadyPaid ? props.appointment.totalAlreadyPaid : 0;
    setTotalToBePaid(parseFloat((props.appointment.totalPrice - (discount + totalAlreadyPaid)).toFixed(2)));
  }, [discount, props.appointment.totalPrice, props.appointment.totalAlreadyPaid]);

  useEffect(() => {
    setTotal((props.appointment.totalPrice).toFixed(2));
  }, [props.appointment.totalPrice]);

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white w-2/3 h-4/5 p-4 rounded-lg shadow-lg relative">
          <button
            onClick={() => props.callBack()}
            className="absolute top-2 right-2 p-3 text-xl text-gray-500 hover:text-gray-700"
          >
            <i className="fa-solid fa-x"></i>
          </button>
          <h1 className="text-2xl mb-4">Pagamento</h1>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
              Cliente
            </label>
            <input
              type="text"
              id="breedId"
              value={props.appointment.customerName}
              disabled={true}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex flex-row space-x-1.5">
            <div className="w-3/12">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
                Pet
              </label>
              <input
                type="text"
                id="breedId"
                value={props.appointment.petName}
                disabled={true}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 w-1/5">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="breedId">
                Raça
              </label>
              <input
                type="text"
                id="breedId"
                value={props.appointment.breed}
                disabled={true}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 w-44">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="size">
                Porte
              </label>
              <input
                type="text"
                id="port"
                value={sizeList.find(s => s.id === props.appointment.size)?.name || ""}
                disabled={true}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-bold mb-2">Detalhes do Preço</h2>
            <div className="max-h-52 overflow-y-auto">
              <table className="min-w-full bg-white sticky-header">
                <thead>
                <tr>
                  <th className="py-2">Descrição</th>
                  <th className="py-2">Preço</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="border px-4 py-2">Banho</td>
                  <td className="border px-4 py-2">{`R$ ${props.appointment.bathPrice}`}</td>
                </tr>
                {props.appointment.extra?.map((extra, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{extra.extraName}</td>
                    <td className="border px-4 py-2">{`R$ ${extra.price}`}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comercialUnit">
                Forma de pagamento
              </label>
              <select
                id="paymentMethod"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Selecionar</option>
                {paymentMethosArray.map((method, index) => (
                  <option key={index} value={index}>
                    {method}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4 ml-3">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total">
                Pagamento
              </label>
              <NumericFormat
                id="paymentPrice"
                value={payment}
                onValueChange={(values) => setPayment(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 ml-3">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total">
                Desconto
              </label>
              <NumericFormat
                id="discount"
                value={discount}
                allowNegative={false}
                onValueChange={(values) => setDiscount(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 ml-3">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total">
                Total pago
              </label>
              <input
                type="text"
                id="total"
                value={props.appointment.totalAlreadyPaid ? `R$ ${props.appointment.totalAlreadyPaid}` : "R$ 0,00"}
                disabled={true}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4 ml-3">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total">
                  Total a pagar
                </label>
                <input
                  type="text"
                  id="total"
                  value={`R$ ${totalToBePaid}`}
                  disabled={true}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mt-3">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total">
                  Total
                </label>
                <input
                  type="text"
                  id="total"
                  value={`R$ ${total}`}
                  disabled={true}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <button
              onClick={handleConfirm}
              className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BathAppointmentPayment;