import React, { useEffect, useState } from 'react';
import MessageBox from "../../Components/MessageBox";
import { useLocation, useNavigate } from "react-router-dom";
import EditSearchable from "../../Components/EditSearchable";

interface PetInterface {
  id: number;
  name: string;
  breed: BreedInterface;
  birthDate: string;
}

interface BreedInterface {
  id: number;
  name: string;
}

const PetList = () => {
  const [customerId, setCustomerId] = useState<number | undefined>();
  const [customerName, setCustomerName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [petList, setPetList] = useState<PetInterface[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const customerIdRedirected = location.state?.customerId;
  const customerNameRedirected = location.state?.customerName;

  useEffect(() => {
    if (customerIdRedirected) {
      setCustomerId(customerIdRedirected);
      setCustomerName(customerNameRedirected);
    }
  }, [customerIdRedirected, customerNameRedirected]);

  useEffect(() => {
    const fetchPetList = async () => {
      if (!customerId) return;
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/customer/${customerId}/pet`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPetList(data);
        } else {
          setErrorMessage("Erro ao buscar os pets");
        }
      } catch (error) {
        setErrorMessage("Erro ao buscar os pets");
      }
    };
    fetchPetList();
  }, [customerId]);

  const filteredPetList = petList.filter(pet =>
    pet.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (pet: PetInterface) => {
    navigate('/pet/alterar', { state: { pet } });
  }
  const handleDeleteClick = async (id: number) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/pet/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response.ok) {
        setPetList(petList.filter(pet => pet.id !== id));
      } else {
        console.error("Erro ao deletar a marca");
      }
    } catch (error) {
      console.error("Erro ao deletar a marca:", error);
    }
  }

  const setCustomerEntity = (entity: any) => {
    setCustomerId(entity.id);
    setCustomerName(entity.name);
  }

  const calculateAge = (birthDate: string) => {
    const birth = new Date(birthDate);
    const now = new Date();
    const years = now.getFullYear() - birth.getFullYear();
    const months = now.getMonth() - birth.getMonth();
    const totalMonths = years * 12 + months;
    const displayYears = Math.floor(totalMonths / 12);
    const displayMonths = totalMonths % 12;
    if (displayYears <= 0 && displayMonths > 0) {
      return `${displayMonths} mês${displayMonths !== 1 ? 'es' : ''}`;
    } else if (displayYears > 0) {
      return `${displayYears} ano${displayYears !== 1 ? 's' : ''} e ${displayMonths} mês${displayMonths !== 1 ? 'es' : ''}`;
    }
    return '';
  };

  return (
    <div>
      <h1 className="text-6xl text-center mb-6">Lista de pets</h1>
      <div className="mb-4 justify-center">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
          Cliente
        </label>
        <div className="flex flex-row">
          <EditSearchable querySearch="/customer/search?name=" setEntity={setCustomerEntity}
                          styles="w-60p" inputText={customerName} />
        </div>
      </div>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Buscar por pet"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="flex justify-center">
        <div className="">
          <table className="table-auto w-full">
            <thead>
            <tr>
              <th className="border px-4 py-2">Nome</th>
              <th className="border px-4 py-2">Raça</th>
              <th className="border px-4 py-2">Idade</th>
              <th className="border px-4 py-2">Editar</th>
              <th className="border px-4 py-2">Excluir</th>
            </tr>
            </thead>
            <tbody>
            {filteredPetList.map((pet) => (
              <tr key={pet.id}>
                <td className="border px-4 py-2">{pet.name}</td>
                <td className="border px-4 py-2">{pet.breed.name}</td>
                <td className="border px-4 py-2">{calculateAge(pet.birthDate)}</td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-edit" onClick={() => handleEditClick(pet)}></i>
                </td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-trash" onClick={() => handleDeleteClick(pet.id)}></i>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PetList;