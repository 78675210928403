import React, { useState, useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MessageBox from "../../Components/MessageBox";
import {ProductInterface} from "../Product";
import EditSearchable from "../../Components/EditSearchable";
import {NumericFormat} from "react-number-format";

const commercialUnits = ["Unidade", "Caixa", "Kg", "Litro"];

function SKUForm() {
  const location = useLocation();
  const skuEntity: ProductInterface = location.state?.product;

  const [name, setName] = useState("");
  const [sku, setSKU] = useState("");
  const [productCode, setProductCode] = useState("");
  const [ncm, setNCM] = useState("");
  const [comercialUnit, setComercialUnit] = useState("");
  const [stockQuantity, setStockQuantity] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [categoryId, setCategoryId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (skuEntity) {
      setName(skuEntity.name);
      setSKU(skuEntity.sku);
      setProductCode(skuEntity.productCode);
      setNCM(skuEntity.ncm);
      setComercialUnit(skuEntity.comercialUnit);
      setStockQuantity(skuEntity.stockQuantity);
      setPrice(skuEntity.price);
      setCategoryId(skuEntity.category?.id.toString() ?? "");
    }
  }, [skuEntity]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');

    const httpMethod = !skuEntity ? 'POST' : 'PUT';
    const url = !skuEntity ? `${process.env.REACT_APP_API_BASE_URL}/v1/sku` : `${process.env.REACT_APP_API_BASE_URL}/v1/sku/${sku}`;

    try {
      const response = await fetch(url, {
        method: httpMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({sku, name, productCode, ncm, comercialUnit, stockQuantity, price, categoryId}),
      });
      if (response.ok) {
        navigate(`/produtos`);
      } else {
        setErrorMessage("Erro ao enviar a sku");
      }
    } catch (error) {
      setErrorMessage("Erro ao enviar a sku");
    }
  };

  const setCategoryEntity = (item: any) => {
    setCategoryId(item.id);
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center">
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <h1 className="text-4xl font-bold my-4">SKU</h1>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Nome
        </label>
        <input
          type="text"
          id="name"
          value={name}
          maxLength={100}
          onChange={(e) => setName(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sku">
          CEAN/GTIN
        </label>
        <input
          type="text"
          id="sku"
          value={sku}
          maxLength={14}
          onChange={(e) => setSKU(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
          Category
        </label>
        <div className="flex flex-row">
          <EditSearchable querySearch="/category/search?name=" setEntity={setCategoryEntity}
                          inputText={skuEntity ? skuEntity.category?.name : ""}
                          styles="w-full"/>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ncm">
          NCM
        </label>
        <input
          type="text"
          id="ncm"
          value={ncm}
          maxLength={12}
          onChange={(e) => setNCM(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comercialUnit">
          Unidade comercial
        </label>
        <select
          id="comercialUnit"
          value={comercialUnit}
          onChange={(e) => setComercialUnit(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Selecione uma unidade</option>
          {commercialUnits.map((unit, index) => (
            <option key={index} value={unit}>
              {unit}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="stockQuantity">
          Quantidade em estoque
        </label>
        <input
          type="number"
          id="stockQuantity"
          value={stockQuantity}
          max={9999}
          onChange={(e) => setStockQuantity(parseFloat(e.target.value))}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          style={{appearance: "none", MozAppearance: "textfield", WebkitAppearance: "none"}}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathGrooming">
          Preço
        </label>
        <NumericFormat
          id="price"
          value={price}
          onValueChange={(values) => setPrice(values.floatValue ? values.floatValue : 0.00)}
          prefix={'R$ '}
          decimalSeparator={','}
          fixedDecimalScale={true}
          decimalScale={2}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Salvar
      </button>
    </form>
  );
}

export default SKUForm;