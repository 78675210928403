import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  const isTokenValid = tokenExpiration && parseInt(tokenExpiration) > new Date().getTime();

  return isTokenValid ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;