import React from 'react';
import {useNavigate} from "react-router-dom";


const InvalidRoute: React.FC = () => {
  const navigate = useNavigate();
  navigate('/');
  return (
    <></>
  );
};

export default InvalidRoute;