import React, { useState, useEffect, useRef } from "react";
import MessageBox from "./MessageBox";
interface SearchComponentProps {
  onClose: () => void;
  onSelect: (item: any) => void;
  querySearch: string;
}

interface SelectedItemInterface {
  id: number;
  name: string;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onClose, onSelect, querySearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<SelectedItemInterface[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const accessToken = localStorage.getItem('accessToken');
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      if (!baseUrl) {
        throw new Error("BASE_URL não foi encontrada.");
      }
      const response = await fetch(baseUrl.concat('/v1').concat(querySearch).concat(searchTerm), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const results = data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setResults(results);
      }
    } catch (error) {
      setErrorMessage("Error ao buscar registros.");
    }

  };

  const handleSelect = (item: any) => {
    onSelect(item);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <div className="bg-white w-1/3 h-1/3 p-4 rounded shadow-lg z-50">
        <div className="flex justify-between mb-4">
          <div className="flex w-full">
            <input
              type="text"
              ref={searchInputRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Buscar..."
            />
            <button
              onClick={handleSearch}
              className="ml-2 bg-blue-500 text-white p-2 rounded"

            >
              Buscar
            </button>
          </div>
          <button
            onClick={onClose}
            className="ml-2 bg-red-500 text-white p-2 rounded"
          >
            Close
          </button>
        </div>
        <ul className="overflow-y-auto h-2/3">
          {results.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSelect(item)}
              className="p-2 hover:bg-gray-200 cursor-pointer"
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchComponent;