import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from './App';
import Login from './Login';
import Category from './Pages/Category';
import CategoryForm from './Pages/Forms/CategoryForm';
import PrivateRoute from './Security/PrivateRoute';
import BrandForm from "./Pages/Forms/BrandForm";
import Brand from "./Pages/Brand";
import Product from "./Pages/Product";
import SKUForm from "./Pages/Forms/SKUForm";
import SalesForm from "./Pages/Forms/SalesForm";
import Appointment from "./Pages/Bath/Appointment";
import AppointmentList from "./Pages/Bath/AppointmentList";
import InvalidRoute from "./Security/InvalidRoute";
import CustomerForm from "./Pages/Customer/CustomerForm";
import PetForm from "./Pages/Customer/PetForm";
import BathList from "./Pages/Bath/BathList";
import ExtrasPriceList from "./Pages/Bath/ExtrasPriceList";
import BathPrice from "./Pages/Bath/BathPrice";
import ExtraPrice from "./Pages/Bath/ExtraPrice";
import DailyResume from "./Pages/Bath/DailyResume";
import CustomerList from "./Pages/Customer/CustomerList";
import PetList from "./Pages/Customer/PetList";
import EmployeeList from "./Pages/Employee/EmployeeList";
import EmployeeForm from './Pages/Employee/EmployeeForm';

function AppRoutes() {
  return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<App />}>
              <Route path="categorias" element={<Category />} />
              <Route path="categorias/inserir" element={<CategoryForm isInsert={true} />} />
              <Route path="categorias/:id" element={<CategoryForm isInsert={false} />} />


              <Route path="/marcas" element={<Brand />} />
              <Route path="/marcas/inserir" element={<BrandForm isInsert={true} />} />
              <Route path="/marcas/:id" element={<BrandForm isInsert={false} />} />


              <Route path={"produtos"} element={<Product />} />
              <Route path={"produtos/inserir"} element={<SKUForm />} />
              <Route path="/produtos/:id" element={<SKUForm />} />


              <Route path={"/skus/:id"} element={<SKUForm />} />
              <Route path={"/skus/inserir"} element={<SKUForm />} />

              <Route path={"/banho"} element={<BathList />} />
              <Route path={"/banho/precos"} element={<BathPrice />} />
              <Route path={"/banho/extras"} element={<ExtrasPriceList />} />
              <Route path={"/banho/extras/precos"} element={<ExtraPrice />} />
              <Route path={"/banho/extras/novo"} element={<ExtraPrice />} />

              <Route path={"/banho/agendar"} element={<Appointment />} />
              <Route path={"/banho/agendamentos"} element={<AppointmentList />} />

              <Route path={"/banho/resumo"} element={<DailyResume />} />

              <Route path={"/venda"} element={<SalesForm />} />

              <Route path="/cliente/cadastrar" element={<CustomerForm />} />
              <Route path="/cliente/alterar" element={<CustomerForm />} />
              <Route path={"/cliente"} element={<CustomerList /> } />

              <Route path="/pet/cadastrar" element={<PetForm />} />
              <Route path="/pet/alterar" element={<PetForm />} />
              <Route path="/pet" element={<PetList />} />

              <Route path={"/funcionario"} element={<EmployeeList /> } />
              <Route path={"/funcionario/inserir"} element={<EmployeeForm /> } />
              <Route path={"/funcionario/alterar"} element={<EmployeeForm /> } />

              <Route path="*" element={<InvalidRoute />} />
            </Route>
          </Route>
        </Routes>
      </Router>
  );
}
export default AppRoutes;