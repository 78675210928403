import React, {useEffect, useState} from 'react';
import MessageBox from "../../Components/MessageBox";
import {useNavigate} from "react-router-dom";

interface EmployeeInterface {
  id: number;
  name: string;
  email: string;
  phone: string;
}

const EmployeeList = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeList, setEmployeeList] = useState<EmployeeInterface[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/employee`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setEmployeeList(data);
        } else {
          setErrorMessage("Erro ao buscar os funcionários");
        }
      } catch (error) {
        setErrorMessage("Erro ao buscar os funcionários");
      }
    }
    fetchEmployeeList();
  }, []);

  const filteredEmployeeList = employeeList.filter(employee =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (employee: EmployeeInterface) => {
    navigate('/funcionario/alterar', {state: {employee}});
  }

  const handleDeleteClick = async (employee: EmployeeInterface) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/employee/${employee.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        setEmployeeList(employeeList.filter(e => e.id !== employee.id));
      } else {
        setErrorMessage("Erro ao deletar o funcionário");
      }
    } catch (error) {
      setErrorMessage("Erro ao deletar o funcionário");
    }
  }

  return (
    <div>
      <h1 className="text-6xl text-center mb-6">Lista de funcionários</h1>
      <div className="flex justify-center">
        <button
          className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded mb-4"
          onClick={() => navigate("/funcionario/inserir")}
        >
          Inserir funcionario
        </button>
      </div>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <div className="flex justify-center mb-4">
        <input
          type="text"
          placeholder="Buscar por funcionário"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="flex justify-center">
        <div className="">
          <table className="table-auto w-full">
            <thead>
            <tr>
              <th className="border px-4 py-2">Nome</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Telefone</th>
              <th className="border px-4 py-2">Editar</th>
              <th className="border px-4 py-2">Excluir</th>
            </tr>
            </thead>
            <tbody>
            {filteredEmployeeList.map((employee) => (
              <tr key={employee.id}>
                <td className="border px-4 py-2">{employee.name}</td>
                <td className="border px-4 py-2">{employee.email}</td>
                <td className="border px-4 py-2">{employee.phone}</td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-edit" onClick={() => handleEditClick(employee)}></i>
                </td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-trash" onClick={() => handleDeleteClick(employee)}></i>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;