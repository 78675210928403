import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBox from "../../Components/MessageBox";
import { ExtraListResponse } from "./ExtrasPriceList";
import { NumericFormat } from "react-number-format";

function ExtraPrice() {
    const location = useLocation();
    const navigate = useNavigate();
    const { extra } = (location.state || {}) as { extra?: ExtraListResponse };

    const [name, setName] = useState(extra ? extra.name : '');
    const [price, setPrice] = useState(extra ? extra.price : 0);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorMessage("");

        const updatedExtra = {
            id: extra ? extra.id : undefined,
            name,
            price,
        };

        try {
            const accessToken = localStorage.getItem('accessToken');
            const method = extra ? 'PUT' : 'POST';
            const url = extra
              ? `${process.env.REACT_APP_API_BASE_URL}/v1/bath/extra/${extra.id}`
              : `${process.env.REACT_APP_API_BASE_URL}/v1/bath/extra`;

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updatedExtra),
            });

            if (response.ok) {
                navigate('/banho/extras');
            } else {
                setErrorMessage("Erro ao atualizar os dados do extra");
            }
        } catch (error) {
            setErrorMessage("Erro ao atualizar os dados do extra");
        }
    };

    return (
      <div>
          {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")} />}
          <h1 className="text-center text-6xl mb-6">{extra ? "Preço de extras" : "Novo Extra"}</h1>
          <form onSubmit={handleSubmit} className="w-full">
              <div className="mb-4 w-80">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                      Nome
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    disabled={extra != null}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
              <div className="mb-4 w-48">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                      Preço
                  </label>
                  <NumericFormat
                    id="price"
                    value={price}
                    onValueChange={(values) => setPrice(values.floatValue ? values.floatValue : 0.00)}
                    prefix={'R$ '}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
              </div>
              <button
                type="submit"
                className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                  {extra ? "Atualizar" : "Cadastrar"}
              </button>
          </form>
      </div>
    );
}

export default ExtraPrice;