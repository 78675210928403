import React, { useState, useEffect } from 'react';

interface PropsInterface {
  componentId: number;
  extra: ExtraInterface;
  setExtra: (extra: ExtraInterface) => void;
  extraList: ExtraInterface[];
  destroy: () => void;
}

interface ExtraInterface {
  id: number;
  name: string;
  price: number;
}

const Extras: React.FC<PropsInterface> = ({ componentId, extra, setExtra, extraList, destroy }) => {
  const [price, setPrice] = useState<number>(extra.price);

  useEffect(() => {
    setPrice(extra.price);
  }, [extra]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedExtra = extraList.find(extra => extra.id === parseInt(e.target.value));
    if (selectedExtra) {
      setExtra({ ...selectedExtra });
      setPrice(selectedExtra.price);
    }
  };

  return (
      <div className="mb-4 w-7xl flex flex-row">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`extra-${componentId}`}>
            Extra
          </label>
          <div className="h-10 w-full">
            <select
                id={`extra-${componentId}`}
                value={extra.id}
                onChange={handleSelectChange}
                className="p-2 mb-6 text-sm text-gray-900 border rounded-lg bg-gray-50 disabled:opacity-100 border-gray-500 h-10 w-full"
            >
              <option value="">Selecione um extra</option>
              {extraList.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
              ))}
            </select>
          </div>
        </div>
        <div className="ml-3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`price-${componentId}`}>
            Preço
          </label>
          <input
              type="text"
              id={`price-${componentId}`}
              value={price.toFixed(2)}
              disabled={true}
              className="shadow appearance-none border rounded w-28 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1"
          />
        </div>
        <button
            className="fa fa-trash ml-2 mt-5"
            onClick={(event) => {
              event.preventDefault();
              destroy();
            }}
        ></button>
      </div>
  );
};

export type { ExtraInterface };
export default Extras;