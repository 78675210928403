import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import MessageBox from "../../Components/MessageBox";
import {BathListResponse} from "./BathList";
import { NumericFormat } from "react-number-format";

function BathPrice() {
  const location = useLocation();
  const navigate = useNavigate();
  const {bath} = location.state as { bath: BathListResponse };

  const [adultBath, setAdultBath] = useState(bath.adultBath);
  const [puppyBath, setPuppyBath] = useState(bath.puppyBath);
  const [bathGrooming, setBathGrooming] = useState(bath.bathGrooming);
  const [bathGroomingH, setBathGroomingH] = useState(bath.bathGroomingH);
  const [bathGroomingScissor, setBathGroomingScissor] = useState(bath.bathGroomingScissor);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage("");

    const updatedBath = {
      breedId: bath.breed.id,
      adultBath,
      puppyBath,
      bathGrooming,
      bathGroomingH,
      bathGroomingScissor,
    };

    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath/${bath.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedBath),
      });

      if (response.ok) {
        navigate('/banho');
      } else {
        setErrorMessage("Erro ao atualizar os dados do banho");
      }
    } catch (error) {
      setErrorMessage("Erro ao atualizar os dados do banho");
    }
  };

  return (
      <div>
        {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
        <h1 className="text-center text-6xl mb-6">Preços banho e tosa</h1>
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-4 w-80">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="breed">
              Raça
            </label>
            <input
                type="text"
                id="breed"
                value={bath.breed.name}
                readOnly
                disabled={true}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="adultBath">
              Banho adulto
            </label>
            <NumericFormat
                id="adultBath"
                value={adultBath}
                onValueChange={(values) => setAdultBath(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="puppyBath">
              Banho filhote
            </label>
            <NumericFormat
                id="puppyBath"
                value={puppyBath}
                onValueChange={(values) => setPuppyBath(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathGrooming">
              Banho e tosa
            </label>
            <NumericFormat
                id="bathGrooming"
                value={bathGrooming}
                onValueChange={(values) => setBathGrooming(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathGroomingH">
              Banho e tosa higienica
            </label>
            <NumericFormat
                id="bathGroomingH"
                value={bathGroomingH}
                onValueChange={(values) => setBathGroomingH(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathGroomingScissor">
              Banho e tosa e tesoura
            </label>
            <NumericFormat
                id="bathGroomingScissor"
                value={bathGroomingScissor}
                onValueChange={(values) => setBathGroomingScissor(values.floatValue ? values.floatValue : 0.00)}
                prefix={'R$ '}
                decimalSeparator={','}
                fixedDecimalScale={true}
                decimalScale={2}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
              type="submit"
              className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Atualizar
          </button>
        </form>
      </div>
  );
}

export default BathPrice;