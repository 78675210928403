import React, { useEffect, useState } from 'react';
import Extras, { ExtraInterface } from './Extras';

interface ExtraListInterface {
  extraList: ExtraInterface[];
  setExtraList: React.Dispatch<React.SetStateAction<ExtraInterface[]>>;
}

const ExtrasList: React.FC<ExtraListInterface> = ({ extraList, setExtraList }) => {
  const [extraFetchList, setExtraFetchList] = useState<ExtraInterface[]>([]);

  useEffect(() => {
    const fetchExtras = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath/extra`);
        if (response.ok) {
          const data: ExtraInterface[] = await response.json();
          setExtraFetchList(data);
        }
      } catch (error) {
        console.error('Error fetching extras', error);
      }
    };
    fetchExtras();
  }, []);

  const addExtra = () => {
    if (extraFetchList.length > 0) {
      setExtraList([...extraList, { ...extraFetchList[0] }]);
    }
  };

  const updateExtra = (index: number, extra: ExtraInterface) => {
    const updatedExtras = [...extraList];
    updatedExtras[index] = extra;
    setExtraList(updatedExtras);
  };

  const removeExtra = (index: number) => {
    const updatedExtras = extraList.filter((_, i) => i !== index);
    setExtraList(updatedExtras);
  };

  return (
      <div className="w-full">
        {extraList.map((extra, index) => (
            <Extras
                key={index}
                componentId={index}
                extra={extra}
                extraList={extraFetchList}
                setExtra={(extra) => updateExtra(index, extra)}
                destroy={() => removeExtra(index)}
            />
        ))}
        <div className="w-80 mb-5 text-center">
          <button className="fa fa-plus h-12 w-48" onClick={(event) => {event.preventDefault(); addExtra();}}></button>
        </div>
      </div>
  );
};

export default ExtrasList;