import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @ts-ignore
import InputMask from "react-input-mask";
import MessageBox from "../../Components/MessageBox";

function EmployeeForm() {
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [address, setAddress] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const employee = location.state?.employee;

  const cpfRef = useRef(null);
  const zipCodeRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    if (employee) {
      setName(employee.name);
      setCpf(employee.cpf);
      setAddress(employee.address);
      setNeighborhood(employee.neighborhood);
      setAddressNumber(employee.addressNumber);
      setZipCode(employee.zipCode);
      setAddressComplement(employee.addressComplement);
      setPhone(employee.phone);
      setEmail(employee.email);
    }
  }, [employee]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');
    const zipCodeMask = zipCode ? zipCode.toString().replace("-", "") : "";
    const phoneMask = phone ? phone.toString().replace("(", "").replace(")", "").replace(" ", "").replace("-", "") : "";

    const employeeData = { name, cpf, address, neighborhood, addressNumber, zipCode: zipCodeMask, addressComplement, phone: phoneMask, email };
    const method = employee ? 'PUT' : 'POST';
    const url = employee ? `${process.env.REACT_APP_API_BASE_URL}/v1/employee/${employee.id}` : `${process.env.REACT_APP_API_BASE_URL}/v1/employee`;

    try {
      console.log(employeeData);
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(employeeData),
      });
      if (response.ok) {
        navigate("/funcionario");
      } else {
        setErrorMessage("Erro ao cadastrar o funcionário");
      }
    } catch (error) {
      setErrorMessage("Erro ao cadastrar o funcionário");
    }
  };

  const handleZipCode = async () => {
    const validacep = /^[0-9]{8}$/;
    if (!validacep.test(zipCode.replace("-", ""))) {
      console.log("CEP inválido", zipCode);
      setErrorMessage("CEP inválido");
      return;
    }
    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
      const data = await response.json();
      setAddress(data.logradouro);
      setNeighborhood(data.bairro);
    } catch (error) {
      setErrorMessage("CEP não encontrado");
    }
  }

  const messageCallBack = () => {
    setErrorMessage("");
  }

  return (
    <div className="bg-gray-100 p-4 rounded">
      <form onSubmit={handleSubmit} className="flex flex-col justify-center">
        <h1 className="text-4xl font-bold my-4 text-center">{employee ? "Alterar Funcionário" : "Cadastrar Funcionário"}</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Nome
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4 w-15p">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
            CPF
          </label>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setCpf(e.target.value)}
            className="shadow appearance-none border rounded w-36 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ref={cpfRef}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipCode">
            CEP
          </label>
          <div className="flex flex-row w-full">
            <InputMask
              mask="99999-999"
              value={zipCode}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setZipCode(e.target.value)}
              className="shadow appearance-none border rounded w-28 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={zipCodeRef}
            />
            <button onClick={(e) => {
              e.preventDefault();
              handleZipCode();
            }}>
              <i className="fa-solid fa-magnifying-glass text-2xl ml-3 my-auto"></i>
            </button>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-20p">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              type="text"
              id="neighborhood"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 ml-3 w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
              Endereço
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-1/12">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressNumber">
              Número
            </label>
            <input
              type="text"
              id="addressNumber"
              value={addressNumber}
              onChange={(e) => setAddressNumber(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressComplement">
              Complemento
            </label>
            <input
              type="text"
              id="addressComplement"
              value={addressComplement}
              onChange={(e) => setAddressComplement(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-1/5">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Telefone
            </label>
            <InputMask
              mask="(99) 99999-9999"
              value={phone}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPhone(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={phoneRef}
            />
          </div>
          <div className="mb-4 ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        {errorMessage && <MessageBox message={errorMessage} callback={messageCallBack} />}
        <button
          type="submit"
          className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Concluir
        </button>
      </form>
    </div>
  );
}

export default EmployeeForm;