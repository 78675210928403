import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const [isSubmenuManOpen, setIsSubmenuManOpen] = useState(false);
  const [isSubmenuBathOpen, setIsSubmenuBathOpen] = useState(false);
  const [isSubmenuCustomerOpen, setIsSubmenuCustomerOpen] = useState(false);
  const [isSubmenuBathPricesOpen, setIsSubmenuBathPricesOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const navigateTo = (path: string) => () => {
    navigate(`/${path}`);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  return (
      <div ref={sidebarRef}>
        <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar"
                aria-controls="default-sidebar" type="button"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={`inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 ${isMenuOpen ? '' : '-translate-x-full'}`}
        >
          <svg className="w-6 h-6" aria-hidden={isMenuOpen} fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
          </svg>
        </button>

        <aside id="default-sidebar"
               className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}   lg:translate-x-0`}
               aria-label="Sidebar">
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <ul className="space-y-2 font-medium">
              <li>
                <button
                    className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    onClick={navigateTo("produtos")}
                >
                  <i className="fa-solid fa-shopping-bag mr-3"></i>
                  Produtos
                </button>
              </li>
              <li>
                <button
                    className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    onClick={navigateTo("venda")}
                >
                  <i className="fa fa-cart-shopping mr-3"></i>
                  Vender
                </button>
              </li>
              <li>
                <button type="button"
                        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={() => setIsSubmenuManOpen(!isSubmenuManOpen)}>
                  <i className="fa-solid fa-gear"></i>
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Manutenção</span>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
                <ul id="dropdown-bath"
                    className={`${isSubmenuManOpen ? 'block ' : 'hidden'} transition-all duration-1000 ease-in-out py-2 space-y-2`}>
                  <li>
                    <button
                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={navigateTo("marcas")}
                    >
                      Marcas
                    </button>
                  </li>
                  <li>
                    <button
                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={navigateTo("categorias")}
                    >
                      Categorias
                    </button>
                  </li>
                </ul>
              </li>
              <li>
                <button type="button"
                        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={() => setIsSubmenuBathOpen(!isSubmenuBathOpen)}>
                  <i className="fa-solid fa-bath"></i>
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Banho e tosa</span>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
                <ul id="dropdown-bath"
                    className={`${isSubmenuBathOpen ? 'block ' : 'hidden'} transition-all duration-1000 ease-in-out py-2 space-y-2`}>
                  <li>
                    <button
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("banho/agendar")}
                    >
                      Agendar
                    </button>
                  </li>
                  <li>
                    <button
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("banho/agendamentos")}
                    >
                      Agendamentos
                    </button>
                  </li>
                  <li>
                    <button
                      className="flex items-center w-full p-1 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("banho/resumo")}
                    >
                      Relatório
                    </button>
                  </li>
                  <li>
                    <button type="button"
                            className="flex items-center w-full p-1 text-gray-900 transition duration-75 rounded-lg pl-8 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                            onClick={() => setIsSubmenuBathPricesOpen(!isSubmenuBathPricesOpen)}>
                      <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Preços</span>
                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                           viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 1 4 4 4-4"/>
                      </svg>
                    </button>
                    <ul id="dropdown-customer"
                        className={`${isSubmenuBathPricesOpen ? '' : 'hidden'} transition-all duration-1000 ease-in-out py-2 space-y-2`}>
                      <button
                        className="flex items-center w-full p-1 text-gray-900 transition duration-75 rounded-lg pl-16 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={navigateTo("banho")}
                      >
                        Banho e tosa
                      </button>
                      <li>
                        <button
                          className="flex items-center w-full p-1 text-gray-900 transition duration-75 rounded-lg pl-16 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                          onClick={navigateTo("banho/extras")}
                        >
                          Extras
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <button type="button"
                        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={() => setIsSubmenuCustomerOpen(!isSubmenuCustomerOpen)}>
                  <i className="fa-solid fa-user"></i>
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Clientes</span>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
                <ul id="dropdown-customer"
                    className={`${isSubmenuCustomerOpen ? '' : 'hidden'} transition-all duration-1000 ease-in-out py-2 space-y-2`}>
                  <button
                    className="flex items-center w-full p-1 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    onClick={navigateTo("cliente/cadastrar")}
                  >
                    Cadastrar cliente
                  </button>
                  <li>
                    <button
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("pet/cadastrar")}
                    >
                      Cadastrar pet
                    </button>
                  </li>
                  <li>
                    <button
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("cliente")}
                    >
                      Listar clientes
                    </button>
                  </li>
                  <li>
                    <button
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={navigateTo("pet")}
                    >
                      Listar pets
                    </button>
                  </li>
                </ul>
                <ul>
                  <button type="button"
                          className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                          onClick={(event) => navigate('funcionario')}>
                    <i className="fa-solid fa-users"></i>
                    <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Funcionarios</span>
                  </button>
                </ul>
              </li>
            </ul>
          </div>
        </aside>
      </div>
);
}

export default Sidebar;