export interface DailyResumeData {
  id: number;
  bathType: number;
  bathPrice: number;
  extra: ExtraResponseInterface[];
  done: boolean;
}

export interface ExtraResponseInterface {
  id: number,
  extraId: number,
  extraName: string,
  price: number
}

export interface BathResume {
  bathType: number;
  quantity: number;
  totalPrice: number;
}

export interface ExtraResume {
  extraId: number;
  extraName: string;
  quantity: number;
  totalPrice: number;
}

export function fetchResume(startDate: string, endDate: string): Promise<DailyResumeData[] | void> {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment/reports?startDate=${startDate}&endDate=${endDate}`)
    .then(async response => {
      if (response.ok) {
        const dailyResumes: DailyResumeData[] = await response.json();
        return dailyResumes;
      }
      throw new Error('Error fetching extras');
    })
    .catch(error => {
      console.error('Error fetching extras', error);
    });
}

export function getBathResumeList(dailyResume: DailyResumeData[]): BathResume[] {
  return dailyResume.reduce((acc, curr) => {
    const existingBathType = acc.find(item => item.bathType === curr.bathType);
    if (existingBathType) {
      existingBathType.quantity += 1;
      existingBathType.totalPrice += curr.bathPrice;
    } else {
      acc.push({
        bathType: curr.bathType,
        quantity: 1,
        totalPrice: curr.bathPrice
      });
    }
    return acc;
  }, [] as BathResume[]);

}

export function getExtraResumeList(dailyResume: DailyResumeData[]): ExtraResume[] {
  return dailyResume.reduce((acc, curr) => {
    curr.extra?.forEach(extra => {
      const existingExtra = acc.find(item => item.extraId === extra.extraId);
      if (existingExtra) {
        existingExtra.quantity += 1;
        existingExtra.totalPrice += extra.price;
      } else {
        acc.push({
          extraId: extra.extraId,
          extraName: extra.extraName,
          quantity: 1,
          totalPrice: extra.price
        });
      }
    });
    return acc;
  }, [] as ExtraResume[]);
}