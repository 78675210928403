import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import MessageBox from "../../Components/MessageBox";

export interface BathListResponse {
  id: number;
  breed: BreedResponse;
  adultBath: number;
  puppyBath: number;
  bathGrooming: number;
  bathGroomingH: number;
  bathGroomingScissor: number;
}

export interface BreedResponse {
  id: number;
  name: string;
}

function BathList() {
  const [bathList, setBathList] = useState<BathListResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const fetchBathList = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data: BathListResponse[] = await response.json();
        console.log(data);
        setBathList(data);
      } else {
        setErrorMessage("Erro ao buscar os agendamentos");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar os agendamentos");
    }
  };

  useEffect(() => {
    fetchBathList();
  }, []);

  const toFixed = (value: number) => {
    return value ? `R$ ${value.toFixed(2)}` : "R$ 0.00";
  }

  const handleEditClick = (bath: BathListResponse) => {
    navigate('/banho/precos', { state: { bath } });
  }

  const filteredBathList = bathList.filter(bath =>
      bath.breed.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <div>
        <h1 className="text-6xl text-center mb-6">Lista de banhos</h1>
        {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")} />}
        <div className="flex justify-center mb-4">
          <input
              type="text"
              placeholder="Buscar por raça"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex justify-center">
          <div className="">
            <table className="table-auto w-full">
              <thead>
              <tr>
                <th className="border px-4 py-2">Raça</th>
                <th className="border px-4 py-2">Banho adulto</th>
                <th className="border px-4 py-2">Banho filhote</th>
                <th className="border px-4 py-2">Banho e tosa</th>
                <th className="border px-4 py-2">Banho e tosa higienica</th>
                <th className="border px-4 py-2">Banho e tosa e tesoura</th>
                <th className="border px-4 py-2">Editar</th>
              </tr>
              </thead>
              <tbody>
              {filteredBathList.map((bath) => (
                  <tr key={bath.id}>
                    <td className="border px-4 py-2">{bath.breed.name}</td>
                    <td className="border px-4 py-2">{toFixed(bath.adultBath)}</td>
                    <td className="border px-4 py-2">{toFixed(bath.puppyBath)}</td>
                    <td className="border px-4 py-2">{toFixed(bath.bathGrooming)}</td>
                    <td className="border px-4 py-2">{toFixed(bath.bathGroomingH)}</td>
                    <td className="border px-4 py-2">{toFixed(bath.bathGroomingScissor)}</td>
                    <td className="border px-4 py-2 text-center">
                      <i className="fas fa-edit" onClick={() => handleEditClick(bath)}></i>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}

export default BathList;