import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MessageBox from "../../Components/MessageBox";

interface CategoryFormProps {
  isInsert: boolean;
}

function CategoryForm(categoryFormProps: CategoryFormProps) {
  const [nome, setNome] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!categoryFormProps.isInsert && id) {
      const fetchCategory = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/category/${id}`);
          const data = await response.json();
          setNome(data.name);
        } catch (error) {
          setErrorMessage("Erro ao buscar a categoria");
        }
      };

      fetchCategory();
    }
  }, [categoryFormProps.isInsert, id]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage("");
    const accessToken = localStorage.getItem('accessToken');
    const category = { name: nome };

    const httpMethod = categoryFormProps.isInsert ? 'POST' : 'PUT';
    const url = categoryFormProps.isInsert ? `${process.env.REACT_APP_API_BASE_URL}/v1/category/` : `${process.env.REACT_APP_API_BASE_URL}/v1/category/${id}`;
    try {
      const response = await fetch(url, {
        method: httpMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(category),
      });

      if (response.ok) {
        setNome("");
        navigate("/categorias");
      } else {
        setErrorMessage("Erro ao enviar a categoria");
      }
    } catch (error) {
      setErrorMessage("Erro ao enviar a categoria");
    }
  };
  const messageCallback = () => {
    setErrorMessage("");
  }

  return (
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold my-4">Categoria</h1>
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
              Nome
            </label>
            <input
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {categoryFormProps.isInsert ? <i className="fas fa-save"></i> : <i className="fas fa-edit"></i>}
          </button>
        </form>
        {errorMessage && <MessageBox message={errorMessage} callback={messageCallback} />}
      </div>
  );
}

export default CategoryForm;