import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

interface BrandProps {
  id: number;
  name: string;
}

const Brand = () => {
  const [brands, setBrands] = useState<BrandProps[]>([]);

  const fetchBrands = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`);
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const deleteBrand = async (id: number) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/brand/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response.ok) {
        console.log("Marca deletada com sucesso");
        fetchBrands();
      } else {
        console.error("Erro ao deletar a marca");
      }
    } catch (error) {
      console.error("Erro ao deletar a marca:", error);
    }
  }

  useEffect(() => {
    fetchBrands();
  }, [brands]);

  return (
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold my-4">Marca</h1>
        <ul className="w-full max-w-md">
          {brands.map((brand) => (
              <li key={brand.id} className="flex justify-between items-center bg-gray-200 p-4 my-2 rounded shadow-md hover:bg-gray-300 transition duration-300">
                <span className="text-lg font-medium">{brand.name}</span>
                <div>
                  <Link to={`/marcas/${brand.id}`} className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300">
                    <i className="fas fa-eye text-black"></i>
                  </Link>
                  <button onClick={() => deleteBrand(brand.id)}
                          className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300">
                    <i className="fas fa-trash text-black"></i>
                  </button>
                </div>
              </li>
          ))}
        </ul>
        <Link to="/marcas/inserir"
              className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300 flex items-center">
          <i className="fas fa-plus mr-2"></i> Inserir marca
        </Link>
      </div>
  );
};

export default Brand;