import React, {useEffect, useState} from 'react';
import MessageBox from "../../Components/MessageBox";
import {useNavigate} from "react-router-dom";

interface CustomerInterface {
  id: number;
  name: string;
  email: string;
  phone: string;
}

const CustomerList = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [customerList, setCustomerList] = useState<CustomerInterface[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/customer`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCustomerList(data);
        } else {
          setErrorMessage("Erro ao buscar os clientes");
        }
      } catch (error) {
        setErrorMessage("Erro ao buscar os clientes");
      }
    }
    fetchCustomerList();
  });

  const filteredCustomerList = customerList.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (customer: CustomerInterface) => {
    navigate('/cliente/alterar', { state: { customer } });
  }

  const handleDeleteClick = async (customer: CustomerInterface) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/customer/${customer.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        setCustomerList(customerList.filter(c => c.id !== customer.id));
      } else {
        setErrorMessage("Erro ao deletar o cliente");
      }
    } catch (error) {
      setErrorMessage("Erro ao deletar o cliente");
    }
  }

  return (
    <div>
      <h1 className="text-6xl text-center mb-6">Lista de clientes</h1>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <div className="flex justify-center mb-4">
        <input
          type="text"
          placeholder="Buscar por cliente"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="flex justify-center">
        <div className="">
          <table className="table-auto w-full">
            <thead>
            <tr>
              <th className="border px-4 py-2">Nome</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Telefone</th>
              <th className="border px-4 py-2">Editar</th>
              <th className="border px-4 py-2">Excluir</th>
            </tr>
            </thead>
            <tbody>
            {filteredCustomerList.map((customer) => (
              <tr key={customer.id}>
                <td className="border px-4 py-2">{customer.name}</td>
                <td className="border px-4 py-2">{customer.email}</td>
                <td className="border px-4 py-2">{customer.phone}</td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-edit" onClick={() => handleEditClick(customer)}></i>
                </td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-trash" onClick={() => handleDeleteClick(customer)}></i>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;