import React, { useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import MessageBox from "../../Components/MessageBox";
import { ExtraResponseInterface } from "./DailyResumeData";
import BathAppointmentPayment from "./BathAppointmentPayment";
import SearchComponent from "../../Components/SearchComponent";

export interface Appointment {
  id: number;
  petName: string;
  customerName: string;
  breed: string;
  serviceDate: string;
  serviceTime: string;
  bathType: number;
  bathPrice: number;
  extra: ExtraResponseInterface[] | null;
  aesthetic: string | null;
  professional: EmployeeResponseInterface | null;
  employee: EmployeeResponseInterface | null;
  size: string;
  done: boolean;
  totalPrice: number;
  totalDiscount?: number;
  totalAlreadyPaid?: number;
}

interface EmployeeResponseInterface {
  id: number;
  name: string;
}

const bathList = [
  'Banho adulto',
  'Banho filhote',
  'Banho e tosa',
  'Banho e tosa higienica',
  'Banho e tosa e tesoura',
];

const sizeList = [
  { id: "P", name: "Pequeno" },
  { id: "M", name: "Médio" },
  { id: "G", name: "Grande" }
];

const AppointmentList: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [serviceDate, setServiceDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [showDone, setShowDone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const dropdownRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const [showPaymentComponent, setShowPaymentComponent] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const fetchAppointments = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment?date=${serviceDate}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const sortedData = data.sort((a: Appointment, b: Appointment) => {
          const dateA = new Date(`${a.serviceDate}T${a.serviceTime}`);
          const dateB = new Date(`${b.serviceDate}T${b.serviceTime}`);
          return dateA.getTime() - dateB.getTime();
        });
        setAppointments(sortedData);
      } else {
        setErrorMessage("Erro ao buscar os agendamentos");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar os agendamentos");
    }
  }, [serviceDate]);

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setServiceDate(e.target.value);
  }

  const toggleDropdown = (id: number) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  }

  const handleDelete = async (id: number) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        fetchAppointments();
      } else {
        setErrorMessage("Erro ao deletar o agendamento");
      }
    } catch (error) {
      setErrorMessage("Erro ao deletar o agendamento");
    }
  }

  const handlePayment = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setShowPaymentComponent(true);
  }

  const handleSelectProfessional = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setIsSearchOpen(true);
    setShowPaymentComponent(false);
  }

  useEffect(() => {
    fetchAppointments();
  }, [serviceDate, fetchAppointments]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen !== null) {
        const ref = dropdownRefs.current.get(dropdownOpen);
        if (ref && !ref.contains(event.target as Node)) {
          setDropdownOpen(null);
        }
      }
    };

    if (dropdownOpen !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleSelect = async (item: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment/${selectedAppointment?.id}/professional/${item.id}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        fetchAppointments();
      } else {
        setErrorMessage("Erro ao atribuir responsável");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar os agendamentos");
    }
    setDropdownOpen(null);
    setSelectedAppointment(null);
  }

  return (
    <div className="p-4">
      <h1 className="text-4xl font-bold my-4">Lista de Agendamentos</h1>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")} />}
      <div className="flex flex-row">
        <div className="mb-4 w-40">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceDate">
            Data
          </label>
          <input
            type="date"
            id="serviceDate"
            value={serviceDate}
            onChange={(e) => onDateChange(e)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center ml-4">
          <input id="default-checkbox" type="checkbox" checked={showDone} onChange={() => setShowDone(!showDone)}
                 className="w-4 h-4 text-gray-600 bg-gray-100"/>
          <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium">Mostrar apenas não concluidos</label>
        </div>
      </div>
      <ul className="grid grid-cols-3 gap-4">
        {appointments.filter(ap => !showDone || !ap.done).map(appointment => (
          <div className="block relative" ref={(el) => el && dropdownRefs.current.set(appointment.id, el)} key={appointment.id}>
            <li className="p-4 border rounded-lg shadow-md bg-white">
              <button className="absolute top-4 right-4 p-2 py-4 w-2" onClick={() => toggleDropdown(appointment.id)}>
                <i className="fa fa-ellipsis-v text-3xl"></i>
              </button>
              {dropdownOpen === appointment.id && (
                <div className="absolute top-8 right-2 bg-white border rounded shadow-lg">
                  <button className="block px-4 py-2 text-left w-full"
                          onClick={() => handleDelete(appointment.id)}>Excluir
                  </button>
                  <button className="block px-4 py-2 text-left w-full"
                          onClick={() => handlePayment(appointment)}>Receber Pagamento
                  </button>
                  <button className="block px-4 py-2 text-left w-full"
                          onClick={() => handleSelectProfessional(appointment)}>Atribuir responsável
                  </button>
                </div>
              )}
              {isSearchOpen && (
                <SearchComponent
                  onClose={() => setIsSearchOpen(false)}
                  onSelect={handleSelect}
                  querySearch={'/employee/search?name='}
                />
              )}
              <p className="text-lg font-semibold"><strong>Cliente:</strong> {appointment.customerName}</p>
              <p className="text-lg font-semibold"><strong>Pet:</strong> {appointment.petName}</p>
              <p><strong>Raça:</strong> {appointment.breed}</p>
              <p><strong>Porte:</strong> {sizeList.find(size => size.id === appointment.size)?.name}</p>
              <p><strong>Data:</strong> {format(new Date(appointment.serviceDate), 'dd/MM/yyyy')}</p>
              <p><strong>Hora:</strong> {appointment.serviceTime}</p>
              <p><strong>Tipo de banho:</strong> {bathList[appointment.bathType]}</p>
              {
                appointment.extra?.map((extra) => (
                  <p key={extra.id}><strong>Extra:</strong> {extra.extraName}</p>
                ))}
              {appointment.professional ? <p><strong>Responsável: </strong> {appointment.professional.name}</p> : 'Sem responsável'}
              <div className='flex flex-row mt-5 mb-auto'>
                <select
                  className="text-sm text-gray-900 border  rounded-lg bg-gray-50 disabled:opacity-100 border-gray-500"
                  value={appointment.done ? 'Concluido' : 'Não concluido'}
                  onChange={(e) => {
                    const accessToken = localStorage.getItem('accessToken');
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment/${appointment.id}/done`, {
                      method: 'PATCH',
                      headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({done: e.target.value === 'Concluido'}),
                    }).then(() => fetchAppointments());
                  }}
                >
                  <option value="Concluido">Concluido</option>
                  <option value="Não concluido">Não concluido</option>
                </select>
              </div>
            </li>
          </div>
        ))}
      </ul>
      {showPaymentComponent && selectedAppointment && (
        <BathAppointmentPayment
          appointment={selectedAppointment}
          callBack={() => setShowPaymentComponent(false)}
        />
      )}
    </div>
  );
};

export default AppointmentList;