import React from 'react';
import Sidebar from './SideBar';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  return (
      <div className="flex h-full">
        <Sidebar />
        <div className="flex-1 p-4 ml-64">
          <Outlet />
        </div>
      </div>
  );
};

export default Layout;