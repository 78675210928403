import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MessageBox from "../../Components/MessageBox";
import EditSearchable from "../../Components/EditSearchable";

function PetForm() {
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [name, setName] = useState("");
  const [breedId, setBreedId] = useState("");
  const [breedName, setBreedName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [allergies, setAllergies] = useState("");
  const [port, setPort] = useState("");
  const [color, setColor] = useState("");
  const [neutered, setNeutered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const pet = location.state?.pet;

  useEffect(() => {
    if (pet) {
      setCustomerId(pet.customer.id);
      setCustomerName(pet.customer.name);
      setName(pet.name);
      setBreedId(pet.breed.id);
      setBreedName(pet.breed.name)
      setBirthDate(pet.birthDate);
      setAllergies(pet.allergies);
      setPort(pet.port);
      setColor(pet.color);
      setNeutered(pet.neutered);
    }
  }, [pet]);

  const setCustomerEntity = (item: any) => {
    setCustomerId(item.id);
    setCustomerName(item.name);
  }

  const setBreedEntity = (item: any) => {
    setBreedId(item.id);
    setBreedName(item.name);
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');
    const petData = { customerId, name, breedId, birthDate, allergies, port, color, neutered };

    const method = pet ? 'PUT' : 'POST';
    const url = pet ? `${process.env.REACT_APP_API_BASE_URL}/v1/pet/${pet.id}` : `${process.env.REACT_APP_API_BASE_URL}/v1/pet`;

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(petData),
      });
      if (response.ok) {
        navigate("/pet", {state: {customerId, customerName}});
      } else {
        setErrorMessage("Error registering the pet");
      }
    } catch (error) {
      setErrorMessage("Error registering the pet");
    }
  };

  const calculateAge = (birthDate: string) => {
    const birth = new Date(birthDate);
    const now = new Date();
    const years = now.getFullYear() - birth.getFullYear();
    const months = now.getMonth() - birth.getMonth();
    const totalMonths = years * 12 + months;
    const displayYears = Math.floor(totalMonths / 12);
    const displayMonths = totalMonths % 12;
    if (displayYears <= 0 && displayMonths > 0) {
      return `${displayMonths} mês${displayMonths !== 1 ? 'es' : ''}`;
    } else if (displayYears > 0) {
      return `${displayYears} ano${displayYears !== 1 ? 's' : ''} e ${displayMonths} mês${displayMonths !== 1 ? 'es' : ''}`;
    }
    return '';
  };

  const goToCustomerForm = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate("/cliente");
  }

  return (
    <div className="bg-gray-100 p-4 rounded">
      <form onSubmit={handleSubmit} className="flex flex-col justify-center">
        <h1 className="text-4xl font-bold my-4 text-center">{pet ? "Alterar Pet" : "Cadastrar Pet"}</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
            Cliente
          </label>
          <div className="flex flex-row">
            <EditSearchable querySearch="/customer/search?name=" setEntity={setCustomerEntity} styles="w-60p"
                            inputText={customerName}/>
            <button className="ml-3 p-2" onClick={goToCustomerForm}><i className="fa fa-plus text-2xl"></i></button>
          </div>
        </div>
        <div className="flex justify-end">
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-40p">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Nome
            </label>
            <input
              type="text"
              id="name"
              disabled={!customerId}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-40p ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="breedId">
              Raça
            </label>
            <EditSearchable disabled={!customerId} querySearch="/pet/breed/search?name=" setEntity={setBreedEntity}
                            styles="w-60p" inputText={breedName}/>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-20p">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="birthDate">
              Data de nascimento
            </label>
            <input
              type="month"
              id="birthDate"
              disabled={!customerId}
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex flex-row text-center my-auto ml-3">
            <span>{calculateAge(birthDate)}</span>
          </div>
          <div className="mb-4 w-10p ml-3">
            <label className="text-gray-700 text-sm font-bold mb-2">
              Porte
            </label>
            <select
              id="port"
              value={port}
              disabled={!customerId}
              onChange={(e) => setPort(e.target.value)}
              className={`h-10 appearance-none block border rounded display w-full py-2 px-3 ${!customerId ? 'block' : ''} leading-tight focus:outline-none focus:shadow-outline`}
            >
              <option value="P">Pequeno</option>
              <option value="M">Médio</option>
              <option value="G">Grande</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-48">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="color">
              Cor
            </label>
            <input
              type="text"
              id="color"
              disabled={!customerId}
              value={color}
              onChange={(e) => setColor(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-50p ml-3 flex items-center mt-6">
            <input id="chbx-castrado" type="checkbox" checked={neutered} disabled={!customerId}
                   onChange={() => setNeutered(!neutered)} className="w-4 h-4 text-gray-600 bg-gray-100"/>
            <label htmlFor="lblchbx-castrado" className="ms-2 text-sm font-medium">Castrado(a)</label>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="allergies">
            Alergias
          </label>
          <input
            type="text"
            id="allergies"
            value={allergies}
            disabled={!customerId}
            onChange={(e) => setAllergies(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
        <button type="submit"
                className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          {pet ? "Alterar" : "Cadastrar"}
        </button>
      </form>
    </div>
  );
}

export default PetForm;