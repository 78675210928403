import React, {useEffect, useState} from 'react'
import SearchComponent from "./SearchComponent";


interface PropsInterface {
  querySearch: string;
  setEntity: (entity: any) => void;
  styles?: string;
  disabled?: boolean;
  inputText?: string;
}

function EditSearchable(props: PropsInterface) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (props.inputText) {
      setName(props.inputText);
    }
  }, [props.inputText]);

  const setSearchBox = () => {
    setIsSearchOpen(true);
  }

  const handleSelect = (item: any) => {
    props.setEntity(item);
    setName(item.name);
    setIsSearchOpen(false);
  }

  return (
      <div className={`flex flex-row relative ${props.styles}`}>
        <input
            type="text"
            id="name"
            value={name}
            disabled={true}
            className={`shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        />
        <button
            onClick={(e) => {
              e.preventDefault();
              setSearchBox();
            }}
            className={`absolute right-0 top-0 mt-2 mr-2 ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={props.disabled}
        >
          <i className="fa-solid fa-magnifying-glass text-2xl"></i>
        </button>
        {isSearchOpen && (
            <SearchComponent
                onClose={() => setIsSearchOpen(false)}
                onSelect={handleSelect}
                querySearch={props.querySearch}
            />
        )}
      </div>
  )
}

export default EditSearchable;