import React, { useState, useEffect } from 'react';
/* adicionar callback pra setar o error message pra null de novo */
interface MessageBoxProps {
  message: string;
  duration?: number;
  callback: () => void;
}

const MessageBox: React.FC<MessageBoxProps> = ({ message, duration = 5000, callback }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!visible) {
    callback();
    return null;
  }

  return (
      <div className="message-box">
        {message}
      </div>
  );
};

export default MessageBox;