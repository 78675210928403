import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MessageBox from "../Components/MessageBox";

export interface ProductInterface {
  sku: string;
  name: string;
  productCode: string;
  ncm: string;
  comercialUnit: string;
  stockQuantity: number;
  price: number;
  category: {
    id: number;
    name: string;
  } | null;
}

function Product() {
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sku`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setErrorMessage("Erro ao buscar os produtos");
      }
    };

    fetchProducts();
  }, []);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    (product.category?.name.toLowerCase().includes(categoryFilter.toLowerCase()) ?? false)
  );

  const goToProductForm = (product: ProductInterface) => {
    navigate(`/produtos/${product.sku}`, { state: { product } });
  }

  const handleDelete = async (sku: string) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sku/${sku}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        setProducts(products.filter(product => product.sku !== sku));
      } else {
        setErrorMessage("Erro ao deletar a sku");
      }
    } catch (error) {
      setErrorMessage("Erro ao deletar a sku");
    }
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-4xl font-bold my-4">Produtos</h1>
      <button
        className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded mb-4"
        onClick={() => navigate("/produtos/inserir")}
      >
        Inserir produto
      </button>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")} />}
      <div className="mb-4 w-full flex ">
        <input
          type="text"
          placeholder="Filtrar por nome"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          className="shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <input
          type="text"
          placeholder="Filtrar por categoria"
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          className=" ml-3 shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <table className="min-w-full bg-white">
        <thead>
        <tr>
          <th className="py-2">Nome</th>
          <th className="py-2">Categoria</th>
          <th className="py-2">Qt. Estoque</th>
          <th className="py-2">Ações</th>
        </tr>
        </thead>
        <tbody>
        {filteredProducts.map(product => (
          <tr key={product.sku}>
            <td className="border px-4 py-2">{product.name}</td>
            <td className="border px-4 py-2">{product.category ? product.category.name : ''}</td>
            <td className="border px-4 py-2">{product.stockQuantity}</td>
            <td className="border px-4 py-2">
              <button
                className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300"
                onClick={() => goToProductForm(product)}
              >
                <i className="fas fa-eye text-gray-800"></i>
              </button>
              <button
                onClick={() => handleDelete(product.sku)}
                className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300"
              >
                <i className="fas fa-trash text-gray-800"></i>
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default Product;