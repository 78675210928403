import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @ts-ignore
import InputMask from "react-input-mask";
import MessageBox from "../../Components/MessageBox";

function CustomerForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const customer = location.state?.customer;

  const phoneRef = useRef(null);
  const cpfRef = useRef(null);
  const zipCodeRef = useRef(null);

  useEffect(() => {
    if (customer) {
      setName(customer.name);
      setEmail(customer.email);
      setPhone(customer.phone);
      setCpf(customer.cpf);
      setZipCode(customer.zipCode);
      setAddress(customer.address);
      setNumber(customer.number);
      setComplement(customer.complement);
      setNeighborhood(customer.neighborhood);
    }
  }, [customer]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');
    const zipCodeMask = zipCode ? zipCode.replace("-", "") : "";

    const customerData = { name, email, phone, cpf, zipCode: zipCodeMask, address, number, complement, neighborhood };
    const method = customer ? 'PUT' : 'POST';
    const url = customer ? `${process.env.REACT_APP_API_BASE_URL}/v1/customer/${customer.id}` : `${process.env.REACT_APP_API_BASE_URL}/v1/customer`;

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(customerData),
      });
      if (response.ok) {
        navigate("/cliente");
      } else {
        setErrorMessage("Erro ao cadastrar o cliente");
      }
    } catch (error) {
      setErrorMessage("Erro ao cadastrar o cliente");
    }
  };

  const handleZipCode = async () => {
    const validacep = /^[0-9]{8}$/;
    if (!validacep.test(zipCode.replace("-", ""))) {
      console.log("CEP inválido", zipCode);
      setErrorMessage("CEP inválido");
      return;
    }
    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
      const data = await response.json();
      setAddress(data.logradouro);
      setNeighborhood(data.bairro);
    } catch (error) {
      setErrorMessage("CEP não encontrado");
    }
  }

  return (
    <div className="bg-gray-100 p-4 rounded">
      <form onSubmit={handleSubmit} className="flex flex-col justify-center">
        <h1 className="text-4xl font-bold my-4 text-center">{customer ? "Alterar Cliente" : "Cadastrar Cliente"}</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Nome
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-1/5">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Telefone
            </label>
            <InputMask
              mask="(99) 99999-9999"
              value={phone}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPhone(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={phoneRef}
            />
          </div>
        </div>
        <div className="mb-4 w-15p">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
            CPF
          </label>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setCpf(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ref={cpfRef}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipCode">
            CEP
          </label>
          <div className="flex flex-row w-full">
            <InputMask
              mask="99999-999"
              value={zipCode}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                setZipCode(e.target.value)
              }}
              className="shadow appearance-none border rounded w-10p py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={zipCodeRef}
            />
            <button onClick={(e) => {
              e.preventDefault();
              handleZipCode();
            }}>
              <i className="fa-solid fa-magnifying-glass text-2xl ml-3 my-auto"></i>
            </button>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-20p">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              type="text"
              id="neighborhood"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 ml-3 w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
              Endereço
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-4 w-1/12">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
              Número
            </label>
            <input
              type="text"
              id="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="complement">
              Complemento
            </label>
            <input
              type="text"
              id="complement"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage('')} />}
        <button
          type="submit"
          className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Concluir
        </button>
      </form>
    </div>
  );
}

export default CustomerForm;