import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface CategoryProps {
  id: number;
  name: string;
}

function Category() {
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/category/`);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const deleteCategory = async (id: number) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/category/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response.ok) {
        console.log("Categoria deletada com sucesso");
        fetchCategories();
      } else {
        console.error("Erro ao deletar a categoria");
      }
    } catch (error) {
      console.error("Erro ao deletar a categoria:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold my-4">Categoria</h1>
        <ul className="w-full max-w-md">
          {categories.map((category) => (
              <li key={category.id} className="flex justify-between items-center bg-gray-200 p-4 my-2 rounded shadow-md hover:bg-gray-300 transition duration-300">
                <span className="text-lg font-medium">{category.name}</span>
                <div>
                  <Link to={`/categorias/${category.id}`} className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300">
                    <i className="fas fa-eye text-gray-800"></i>
                  </Link>
                  <button onClick={() => deleteCategory(category.id)}
                          className="text-white font-bold py-1 px-2 rounded mx-1 transition duration-300">
                    <i className="fas fa-trash text-gray-800"></i>
                  </button>
                </div>
              </li>
          ))}
        </ul>
        <Link to="/categorias/inserir"
              className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500 text-white text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300 flex items-center">
          <i className="fas fa-plus mr-2"></i> Inserir categoria
        </Link>
      </div>
  );
}

export default Category;