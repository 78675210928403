import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import MessageBox from "../../Components/MessageBox";
import EditSearchable from "../../Components/EditSearchable";


interface SkuInterface {
  sku: string;
  name: string;
  comercialUnit: string;
  quantity: number;
  price: number;
  totalPrice: number;
  discount: number;
}

const paymentMethosArray = ["Dinheiro", "Pix", "Cartão (Débito)", "Cartão (Crédito)"];

function SalesForm() {
  const [customerId, setCustomerId] = useState("");
  const [sku, setSku] = useState("");
  const [quantity, setQuantity] = useState("");
  const [discount, setDiscount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [products, setProducts] = useState<SkuInterface[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const setCustomerEntity = (item: any) => {
    setCustomerId(item.id);
  }

  const handleAddProduct = async () => {
    if (sku && quantity) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sku/${sku}`);
        if (!response.ok) {
          throw new Error("Failed to fetch SKU");
        }
        const fetchedSku = await response.json();
        setProducts([...products, {...fetchedSku, quantity: Number(quantity), discount: Number(discount)}]);
        setSku("");
        setQuantity("");
        setDiscount("");
      } catch (error) {
        setErrorMessage("Erro ao buscar SKU");
      }
    } else {
      setErrorMessage("Please fill in all product fields");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');

    const items = products.map((p) => ({sku: p.sku, quantity: p.quantity, discount: p.discount}));

    const saleData = {customerId, items: items, paymentMethod: paymentMethod};
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(saleData),
      });
      if (response.ok) {
        setSku("");
        setQuantity("");
        setDiscount("");
        setPaymentMethod("");
        setProducts([]);
        navigate("/venda", {replace: true});
      } else {
        setErrorMessage("Erro ao cadastrar a venda");
      }
    } catch (error) {
      setErrorMessage("Erro ao cadastrar a venda");
    }
  };

  const totalValue = products.reduce((acc, p) => acc + p.quantity * p.price, 0);
  const totalDiscount = products.reduce((acc, p) => acc + p.discount, 0); // Assuming discount is quantity for example
  const handleDeleteProduct = (index: number) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const messageCallback = () => {
    setErrorMessage("");
  }

  const goToCustomerForm = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate("/cliente");
  }

  useEffect(() => {
    let barcode = "";
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleAddProduct();
        barcode = "";
      } else {
        barcode += event.key;
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <h1 className="text-4xl font-bold my-4">Cadastrar Venda</h1>
      <div className="mb-4 w-1/6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product">
          Produto
        </label>
        <input
          type="text"
          id="product"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4 w-24">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
          Quantidade
        </label>
        <input
          type="number"
          id="quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4 w-24">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
          Desconto
        </label>
        <input
          type="number"
          id="discount"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        type="button"
        onClick={handleAddProduct}
        disabled={!sku || !quantity}
        className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 w-56 rounded focus:outline-none focus:shadow-outline mb-4"
      >
        Adicionar Produto
      </button>
      {products.length > 0 && (
        <div className="mb-4 w-full">
          <h2 className="text-2xl font-bold mb-2">Produtos na Venda</h2>
          <table className="bg-white w-full">
            <thead>
            <tr>
              <th className="py-2">Nº</th>
              <th className="py-2">Código</th>
              <th className="py-2">Nome</th>
              <th className="py-2">Unidade comercial</th>
              <th className="py-2">Quantidade</th>
              <th className="py-2">Valor un.</th>
              <th className="py-2">Valor total</th>
              <th className="py-2">Desconto</th>
              <th className="py-2">Valor final</th>
            </tr>
            </thead>
            <tbody>
            {products.map((p, index) => (
              <tr key={index}>
                <td className="w-12 border px-4 py-2 ">{index + 1}</td>
                <td className="w-1/6 border px-4 py-2 ">{p.sku}</td>
                <td className="w-2/6 border px-4 py-2 ">{p.name}</td>
                <td className="w-20 border px-4 py-2 ">{p.comercialUnit}</td>
                <td className="border px-4 py-2 ">{p.quantity}</td>
                <td className="border px-4 py-2 ">R$ {p.price}</td>
                <td className="border px-4 py-2 ">R$ {p.price * p.quantity}</td>
                <td className="border px-4 py-2 ">R$ {p.discount}</td>
                <td className="border px-4 py-2 ">R$ {(p.price * p.quantity) - p.discount}</td>
                <td className="border px-4 py-2 ">
                  <button
                    onClick={() => handleDeleteProduct(index)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          <div className="bg-gray-200 p-4 mt-4 rounded">
            <p className="text-lg font-bold">Valor total: R$ {totalValue}</p>
            <p className="text-lg font-bold">Descontos: R$ {totalDiscount}</p>
            <p className="text-lg font-bold">Valor final: R$ {totalValue - totalDiscount}</p>
          </div>
        </div>
      )}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
          Cliente
        </label>
        <div className="flex flex-row">
          <EditSearchable querySearch="/customer/search?name=" setEntity={setCustomerEntity}
                          styles="w-60p"/>
          <button className="ml-3 p-2" onClick={(event) => goToCustomerForm(event)}><i
            className="fa fa-plus text-2xl"></i></button>
        </div>
      </div>
      <div className="mb-4 w-40">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comercialUnit">
          Forma de pagamento
        </label>
        <select
          id="comercialUnit"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Selecionar</option>
          {paymentMethosArray.map((method, index) => (
            <option key={index} value={index}>
              {method}
            </option>
          ))}
        </select>
      </div>
      {errorMessage && <MessageBox message={errorMessage} callback={messageCallback}/>}
      <button
        type="submit"
        className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Concluir
      </button>
    </form>
  );
}

export default SalesForm;