import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface BrandFormProps {
  isInsert: boolean;
}

function BrandForm(brandFormProps: BrandFormProps) {
  const [nome, setNome] = useState("");
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!brandFormProps.isInsert && id) {
      const fetchBrand = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/brand/${id}`);
          const data = await response.json();
          setNome(data.name);
        } catch (error) {
          console.error("Error fetching brand:", error);
        }
      };

      fetchBrand();
    }
  }, [brandFormProps.isInsert, id]);

  const handleSubmit = async (event: React.FormEvent) => {
    const accessToken: string | null = localStorage.getItem('accessToken');
    event.preventDefault();
    const brand = { name: nome };

    const httpMethod: string = brandFormProps.isInsert ? 'POST' : 'PUT';
    const url: string = brandFormProps.isInsert ? `${process.env.REACT_APP_API_BASE_URL}/brand/` : `${process.env.REACT_APP_API_BASE_URL}/brand/${id}`;

    try {
      const response = await fetch(url, {
        method: httpMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(brand),
      });

      if (response.ok) {
        console.log("Marca enviada com sucesso");
        setNome("");
        navigate("/marcas");
      } else {
        console.log(JSON.stringify(response.body));
        console.error("Erro ao enviar a marca");
      }
    } catch (error) {
      console.error("Erro ao enviar a marca: ", error);
    }
  };

  return (
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold my-4">Inserir marca</h1>
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
              Nome
            </label>
            <input
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {brandFormProps.isInsert ? <i className="fas fa-save"></i> : <i className="fas fa-edit"></i>}
          </button>
        </form>
      </div>
  );
}

export default BrandForm;