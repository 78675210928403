import React, {MouseEventHandler, useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditSearchable from "../../Components/EditSearchable";
import {ExtraInterface} from "./Extras";
import MessageBox from "../../Components/MessageBox";
import ExtrasList from "./ExtrasList";
import {toZonedTime} from "date-fns-tz";


interface ModelsInterface {
  id: number;
  name: string;
}

interface CustomerPetsInterface {
  id: number;
  name: string;
  breed: {
    id: number;
    name: string;
  },
  port: string;
}

interface BathInterface {
  id: number;
  adultBath: number;
  puppyBath: number;
  bathGrooming: number;
  bathGroomingH: number;
  bathGroomingScissor: number;
}

const bathList = [
  {id: 1, name: 'Banho adulto'},
  {id: 2, name: 'Banho filhote'},
  {id: 4, name: 'Banho e tosa'},
  {id: 3, name: 'Banho e tosa higienica'},
  {id: 5, name: 'Banho e tosa e tesoura'},
]

const sizeList = [
  {id: 'P', name: 'Pequeno'},
  {id: 'M', name: 'Médio'},
  {id: 'G', name: 'Grande'},
]


function Appointment() {
  const [customerId, setCustomerId] = useState<number>();
  const [petId, setPetId] = useState("");
  const [serviceDate, setServiceDate] = useState(toZonedTime(new Date(), 'America/Sao_Paulo').toISOString().split('T')[0]);
  const [serviceTime, setServiceTime] = useState(toZonedTime(new Date(), 'America/Sao_Paulo').toString().split(' ')[4].slice(0, 5));
  const [bathType, setBathType] = useState("");
  const [size, setSize] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [petSuggestions, setPetSuggestions] = useState<ModelsInterface[]>([]);
  const [breedSelected, setBreedSelected] = useState<ModelsInterface>();
  const [customerPets, setCustomerPets] = useState<CustomerPetsInterface[]>([]);
  const [bathEntity, setBathEntity] = useState<BathInterface>();
  const [extraList, setExtraList] = useState<ExtraInterface[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [dealerId, setDealerId] = useState<number>();
  const [professionalId, setProfessionalId] = useState<number>();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');

    const appointmentData = {
      petId,
      breedId: breedSelected?.id,
      serviceDate,
      serviceTime: serviceTime.concat(":00"),
      bathType,
      extra: extraList.map((extra) => extra.id),
      size,
      employeeId: dealerId,
      professionalId: professionalId
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath-appointment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(appointmentData),
      });
      if (response.ok) {
        navigate("/banho/agendamentos");
      } else {
        setErrorMessage("Erro ao cadastrar o agendamento");
      }
    } catch (error) {
      setErrorMessage("Erro ao cadastrar o agendamento");
    }
  };

  const fetchPets = async (customerId: number) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/customer/${customerId}/pet`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data: CustomerPetsInterface[] = await response.json();
        const transformedData = data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setCustomerPets(data);
        setPetSuggestions(transformedData);
      } else {
        setErrorMessage("Erro ao buscar os pets");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar os pets");
    }
  }

  useEffect(() => {
    if (customerId) {
      fetchPets(customerId);
      if (breedSelected) {
        fetchBath(breedSelected.id);
      }
    }
  }, [customerId, breedSelected]);

  const getBathPrice = useCallback(() => {
    if (bathEntity) {
      switch (bathType) {
        case "1":
          return bathEntity.adultBath;
        case "2":
          return bathEntity.puppyBath;
        case "3":
          return bathEntity.bathGrooming;
        case "4":
          return bathEntity.bathGroomingH;
        case "5":
          return bathEntity.bathGroomingScissor;
      }
    }
    return 0;
  }, [bathEntity, bathType]);

  const fetchBath = async (breedId: number) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath/${breedId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data: BathInterface = await response.json();
        setBathEntity(data);
      } else {
        setErrorMessage("Erro ao buscar preços de banho");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar preços de banho");
    }
  }
  const handlePetSelected = (pet: ModelsInterface | undefined) => {
    if (pet) {
      setPetId(pet.id.toString());
      setBreedSelected(customerPets.filter((item) => item.id === pet.id)[0].breed);
      setSize(customerPets.filter((item) => item.id === pet.id)[0].port);
    } else {
      setPetId("");
      setBreedSelected(undefined);
      setSize("");
    }
  }

  const setCustomerEntity = (entity: any) => {
    setCustomerId(entity.id);
  }

  const messageCallBack = () => {
    setErrorMessage("");
  }

  useEffect(() => {
    const calculateTotal = () => {
      let total = getBathPrice();
      extraList.forEach((extra) => {
        total += extra.price;
      });
      setTotal(total);
    };
    calculateTotal();
  }, [extraList, getBathPrice]);

  const goToCustomerForm: MouseEventHandler = (event) => {
    event.preventDefault();
    navigate("/cliente");
  }
  const cancel: MouseEventHandler = (event) => {
    event.preventDefault();
    setCustomerId(undefined);
    formRef.current?.reset();
  }

  const setDealerEntity = (entity: any) => {
    setDealerId(entity.id);
  }

  const setProfessionalEntity = (entity: any) => {
    setProfessionalId(entity.id);
  }

  return (
    <div className="bg-gray-100 p-4 rounded">
      <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col justify-center" noValidate>
        <h1 className="text-4xl font-bold my-4 text-center">Agendar banho e tosa</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
            Cliente
          </label>
          <div className="flex flex-row">
            <EditSearchable querySearch="/customer/search?name=" setEntity={setCustomerEntity}
                            styles="w-72"/>
            <button className="ml-3 p-2" onClick={(event) => goToCustomerForm(event)}><i
              className="fa fa-plus text-2xl"></i></button>
          </div>
        </div>
        <div className="flex flex-row space-x-1.5">
          <div className="w-3/12">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
              Pet
            </label>
            <select id="comboBoxPet"
                    className="w-full p-2 mb-6 text-sm text-gray-900 border  rounded-lg bg-gray-50 disabled:opacity-100 border-gray-500"
                    disabled={!customerId}
                    value={petId}
                    onChange={(e) => {
                      const selectedPet = petSuggestions.find(pet => pet.id.toString() === e.target.value);
                      if (selectedPet) {
                        handlePetSelected(selectedPet);
                      }
                    }}>
              <option value="">Selecione um pet</option>
              {petSuggestions.map((pet) => (
                <option key={pet.id} value={pet.id}>
                  {pet.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 w-1/5">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="breedId">
              Raça
            </label>
            <input
              type="text"
              id="breedId"
              value={breedSelected ? breedSelected.name : ""}
              disabled={true}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div className="mb-4 w-44">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="size">
              Porte
            </label>
            <input
              type="text"
              id="port"
              value={sizeList.find(s => s.id === size)?.name || ""}
              disabled={true}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
        </div>
        <div className="flex flex-row space-x-1.5">
          <div className="mb-4 w-40">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceDate">
              Data
            </label>
            <input
              type="date"
              id="serviceDate"
              value={serviceDate}
              onChange={(e) => setServiceDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-28">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="serviceTime">
              Hora
            </label>
            <input
              type="time"
              id="serviceTime"
              value={serviceTime}
              onChange={(e) => setServiceTime(e.target.value + ":00")}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        <div className="flex flex-row w-full h-20">
          <div className="mb-4 w-50">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathType">
              Tipo de banho
            </label>
            <select
              id="bathType"
              value={bathType}
              onChange={(e) => setBathType(e.target.value)}
              className="block w-full p-2 mb-6 text-sm text-gray-900 border rounded-lg outline-none bg-gray-50 focus:outline-none"
            >
              <option value="">Selecione um tipo de banho</option>
              {bathList.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 w-40 ml-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathType">
              Preço
            </label>
            <input
              type="text"
              id="price"
              value={`R$ ${getBathPrice()}`}
              disabled={true}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <div className="flex flex-row space-x-1.5">
          <ExtrasList extraList={extraList} setExtraList={setExtraList}/>
        </div>

        <div className="flex flex-row mb-4 w-40 items-center">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bathType">
            Total:
          </label>
          <input
            type="text"
            id="price"
            value={`R$ ${total}`}
            disabled={true}
            className="shadow ml-3 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="border border-2 p-2">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
              Vendedor
            </label>
            <div className="flex flex-row">
              <EditSearchable querySearch="/employee/search?name=" setEntity={setDealerEntity}
                              styles="w-80"/>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petId">
              Responsavel
            </label>
            <div className="flex flex-row">
              <EditSearchable querySearch="/employee/search?name=" setEntity={setProfessionalEntity}
                              styles="w-80"/>
            </div>
          </div>
        </div>
        {errorMessage && <MessageBox message={errorMessage} callback={messageCallBack}/>}
        <div className="flex flex-row self-end mt-3">
          <button
            onClick={(event) => cancel(event)}
            className="bg-gray-500 w-36 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  ml-3 w-36 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Agendar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Appointment;