import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import MessageBox from "../../Components/MessageBox";

export interface ExtraListResponse {
  id: number;
  name: string;
  price: number;
}

function ExtrasPriceList() {
  const [extraList, setExtraList] = useState<ExtraListResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const fetchExtraList = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath/extra`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data: ExtraListResponse[] = await response.json();
        setExtraList(data);
      } else {
        setErrorMessage("Erro ao buscar os extras");
      }
    } catch (error) {
      setErrorMessage("Erro ao buscar os extras");
    }
  };

  useEffect(() => {
    fetchExtraList();
  }, []);

  const toFixed = (value: number) => {
    return value ? `R$ ${value.toFixed(2)}` : "R$ 0.00";
  }

  const handleEditClick = (extra: ExtraListResponse) => {
    navigate('/banho/extras/precos', {state: {extra}});
  }

  const filteredExtraList = extraList.filter(extra =>
    extra.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDeleteClick = async (extra: ExtraListResponse) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/bath/extra/${extra.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        fetchExtraList();
      } else {
        setErrorMessage("Erro ao excluir o extra");
      }
    } catch (error) {
      setErrorMessage("Erro ao excluir o extra");
    }
  }

  return (
    <div>
      <h1 className="text-6xl text-center mb-6">Lista de extras</h1>
      {errorMessage && <MessageBox message={errorMessage} callback={() => setErrorMessage("")}/>}
      <div className="flex justify-center mb-4">
        <input
          type="text"
          placeholder="Buscar por extra"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="flex justify-center mb-4">
        <button
          onClick={() => navigate('/banho/extras/novo')}
          className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Adicionar extra
        </button>
      </div>
      <div className="flex justify-center">
        <div className="">
          <table className="table-auto w-full">
            <thead>
            <tr>
              <th className="border px-4 py-2">Nome</th>
              <th className="border px-4 py-2">Preço</th>
              <th className="border px-4 py-2">Editar</th>
              <th className="border px-4 py-2">Excluir</th>
            </tr>
            </thead>
            <tbody>
            {filteredExtraList.map((extra) => (
              <tr key={extra.id}>
                <td className="border px-4 py-2">{extra.name}</td>
                <td className="border px-4 py-2">{toFixed(extra.price)}</td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-edit" onClick={() => handleEditClick(extra)}></i>
                </td>
                <td className="border px-4 py-2 text-center">
                  <i className="fas fa-trash" onClick={() => handleDeleteClick(extra)}></i>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ExtrasPriceList;