import React, {useEffect, useState} from 'react';
import {
  fetchResume,
  DailyResumeData,
  getBathResumeList,
  BathResume,
  getExtraResumeList,
  ExtraResume
} from "./DailyResumeData";
import numberFixed from "../../Components/NumberSpan";

const bathList = [
  'Banho adulto',
  'Banho filhote',
  'Banho e tosa',
  'Banho e tosa higienica',
  'Banho e tosa e tesoura',
]

function DailyResume() {
  const [dailyResume, setDailyResume] = useState<DailyResumeData[]>([]);
  const [bathResume, setBathResume] = useState<BathResume[]>([]);
  const [extraResume, setExtraResume] = useState<ExtraResume[]>([]);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [showDone, setShowDone] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchResume(startDate, endDate);
      if (result) {
        setDailyResume(result);
      }
    };
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (dailyResume) {
      const filteredResult = showDone ? dailyResume.filter(item => item.done === showDone) : dailyResume;
      setBathResume(getBathResumeList(filteredResult));
      setExtraResume(getExtraResumeList(filteredResult));
    }
  }, [dailyResume, showDone]);


  return (
    <div>
      <h1 className="text-center text-5xl">Relatório</h1>
      <div>
        <h2 className="text-2xl mb-3">Filtros</h2>
        <div className="flex flex-row">
          <div>
            <span>Inicio</span>
            <input type="date" className="border border-gray-300 p-1 ml-3" value={startDate}
                   onChange={(e) => setStartDate(e.target.value)}/>
          </div>
          <div className="ml-3">
            <span>Fim</span>
            <input type="date" className="border border-gray-300 p-1 ml-3" value={endDate}
                   onChange={(e) => setEndDate(e.target.value)}/>
          </div>
          <div className="flex items-center ml-3">
            <input id="default-checkbox" type="checkbox" checked={showDone} onChange={() => setShowDone(!showDone)}
                   className="w-4 h-4 text-gray-600 bg-gray-100"/>
            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium">Mostrar apenas concluidos</label>
          </div>
        </div>
      </div>
      <h2 className="text-2xl mb-3">Banho e tosa</h2>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right mb-3">
          <thead className="text-xs text-gray-700 uppercase border ">
          <tr>
            <th scope="col" className="px-6 py-3">Tipo</th>
            <th scope="col" className="px-6 py-3">Quantidade</th>
            <th scope="col" className="px-6 py-3">Total</th>
          </tr>
          </thead>
          <tbody>
          {bathResume.map((bath, index) => (
            <tr key={index} className="bg-white border ">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">{bathList[bath.bathType]}</td>
              <td className="px-6 py-4">{bath.quantity}</td>
              <td className="px-6 py-4">R$ {numberFixed(bath.totalPrice)}</td>
            </tr>
          ))}
          <tr id="total" className="bg-white border ">
            <td className="px-6 py-4"><strong>Total</strong></td>
            <td className="px-6 py-4">{bathResume.reduce((sum, bath) => sum + bath.quantity, 0)}</td>
            <td className="px-6 py-4">R$ {numberFixed(bathResume.reduce((sum, bath) => sum + bath.totalPrice, 0))}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <h2 className="text-2xl mb-3">Extras</h2>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right ">
          <thead className="text-xs text-gray-700 uppercase border ">
          <tr>
            <th scope="col" className="px-6 py-3">Tipo</th>
            <th scope="col" className="px-6 py-3">Quantidade</th>
            <th scope="col" className="px-6 py-3">Total</th>
          </tr>
          </thead>
          <tbody>
          {extraResume.map((extra, index) => (
            <tr key={index} className="bg-white border ">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">{extra.extraName}</td>
              <td className="px-6 py-4">{extra.quantity}</td>
              <td className="px-6 py-4">R$ {numberFixed(extra.totalPrice)}</td>
            </tr>
          ))}
          <tr id="total" className="bg-white border ">
            <td className="px-6 py-4"><strong>Total</strong></td>
            <td className="px-6 py-4">{extraResume.reduce((sum, extra) => sum + extra.quantity, 0)}</td>
            <td className="px-6 py-4">R$ {numberFixed(extraResume.reduce((sum, extra) => sum + extra.totalPrice, 0))}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DailyResume;